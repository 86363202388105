import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import PageContainer from '../../components/common/page-container'

export default (props) => (
  <Layout title="Legacy Applications">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>Legacy Applications</Title>
      </TitleContainer>
    </BackgroundContainer>

    <div>
      <PageContainer>
          <p>
              Was your product developed a few years ago, and struggling to meet your current needs? Perhaps your underlying technology needs to be refreshed? Or do you have a mission-critical part of your business running on some old code? We can help.
          </p>
          <p>Using legacy applications can cause a lot of problems for your business which may result in hours of lost productivity. We have worked in the industry for many years and have seen the pains of businesses using outdated software to run their day-to-day operations. packagedby can work with you and your business to put together a modernisation roadmap and update or migrate to a brand new system.</p>
          <h2>Gradually bring your old systems up to modern standards</h2>
          <p>Legacy systems can be critical to your business, and may have cost a significant amount of money. Often, such systems do a great job, but make adding new features or keeping them secure can be difficult.</p>
          <p>We can mitigate the risk by upgrading key parts of your legacy systems, documenting existing functionality, or introducing automated tests to provide assurance to your board.</p>
          <h2>Security, compliance and integrations</h2>
          <p>Older software needs to be upgraded to ensure it can run on more modern systems. Without this, systems can be at risk of exploitation if they make use of un-patched software, run on out of date servers, or were not developed with security as a key consideration.</p>
          <p>We can upgrade underlying components, ensure your legacy systems can run in a modern environment, and limit the risk of security vulnerabilities.</p>
          <p>Legislation and regulatory changes can have a regular impact on systems, which you need to be on-top of. Be this data protection, payment processing, or changing regulatory frameworks for certain industries or business processes.</p>
          <p>Systems also regularly integrate with third party services, be it to process payments, to connect different business processes, send reminders to clients, to share to a users social networking accounts, let users login with their active directory or Google apps account,  or pull data from third party services.</p>
          <p>Over time, these services evolve and disable older functionality.</p>
          <p>If you don't maintain these systems, they can stop working or behave in unexpected ways.</p>
          <h2>Refactor or re-build systems</h2>
          <p>
              Sometimes, a gradual improvement or upgrade isn't enough. We can fully analyse your existing legacy systems, and also provide more aggressive strategies to refactor or replace those systems.
          </p>
      </PageContainer>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "legacy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
